<template>
  <div class="table-responsive">
    <table class="table table-row-bordered">
      <thead class="fs-6 fw-bolder border-bottom">
        <th>Portal</th>
        <th>{{ dataYear }}</th>
        <th>{{ parseInt(dataYear) + 5 }}</th>
        <th>Procent</th>
      </thead>
      <tbody>
        <tr v-for="(item, i) in data" :key="i">
          <td class="">
            <img class="img-fluid" :src="item.img" alt="Logo Partai" />
            <span class="text-primary"> {{ item.name }} </span>
          </td>
          <td class="align-middle">
            <div
              class="bg-default"
              style="height: 1vw"
              :style="{ maxWidth: item.beforePercent + '%' }"
            ></div>
            <br />
            <span class="text-muted">
              {{ Intl.NumberFormat().format(item.beforeAmount) }} Suara
            </span>
          </td>
          <td class="align-middle">
            <div
              class="bg-default"
              style="height: 1vw"
              :style="{ maxWidth: item.afterPercent + '%' }"
            ></div>
            <br />
            <span class="text-muted">
              {{ Intl.NumberFormat().format(item.afterAmount) }} Suara
            </span>
          </td>
          <td class="align-middle">
            <div class="d-flex jusitify-content-start align-items-center">
              <div
                :class="
                  item.resultAmount < 0
                    ? 'bg-danger'
                    : item.resultPercent > 30
                    ? 'bg-success'
                    : 'bg-warning'
                "
                class="flex-grow-1"
                style="height: 1vw"
                :style="{ maxWidth: item.resultPercent + '%', flex: 1 }"
              ></div>
              <span class="float-start ms-3"> {{ item.resultPercent }}% </span>
            </div>
            <i
              class="float-end fa-lg bi"
              :class="
                item.resultAmount > 0
                  ? 'bi-arrow-up-short text-primary'
                  : 'bi-arrow-down-short'
              "
            ></i>
            <br />
            <span class="text-muted">
              {{ Intl.NumberFormat().format(item.resultAmount) }} Suara
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
import CryptoJS from "crypto-js";
import { useRouter } from "vue-router";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "MapComparison-Pileg",
  props: ["id", "year"],
  setup(props) {
    const store = useStore();
    const router = useRouter();
    store.commit("SET_ACTIVE_MENU", "map-comparison");

    const path = window.location.origin + "/other/png/partai/";

    const dataId = CryptoJS.AES.decrypt(props.id, "PKS.id").toString(
      CryptoJS.enc.Utf8
    );
    const dataYear =
      CryptoJS.AES.decrypt(props.year, "PKS.id").toString(CryptoJS.enc.Utf8) -
      5;
    if (!dataId && !dataYear) {
      router.push({ name: "comparison.map" });
    }

    const result = ref(0);

    store.dispatch("getPartyComparison", {});
    // store.dispatch("getPartyComparison", {
    //   territory: dataId,
    //   year: parseInt(dataYear) + 5,
    //   type: 2,
    // });

    const data = ref([]);
    const party = computed(() => store.state.ComparisonModule.party);
    const party1 = computed(() => store.state.ComparisonModule.party1);
    const party2 = computed(() => store.state.ComparisonModule.party2);
    const total1 = ref(null);
    const total2 = ref(null);

    onMounted(() => {
      getCountParty(1, dataYear, dataId, "total");
      getCountParty(1, parseInt(dataYear) + 5, dataId, "total");
    });

    const getCountParty = async (id, year, territory, type = null) => {
      let params = "";
      params += `&territory_id=${territory}`;
      params += `&years=${year}`;
      if (!type) {
        params += `&party_id=${id}`;
      }
      let result = 0;
      await ApiService.get(`api/v1/vote/pilegs?type=party${params}`).then(
        async (res) => {
          if (type == "total") {
            await sumCountParty(res.data.data.counts, year);
          } else {
            if (res.data.data.pilegs[0]) {
              result = await res.data.data.pilegs[0].party.count;
              return result;
            }
          }
        }
      );
      return result;
    };

    const sumCountParty = (data, year) => {
      if (year == dataYear) {
        if (!total1.value) {
          total1.value = data;
        }
      } else {
        if (!total2.value) {
          total2.value = data;
        }
      }
    };

    const loadCountParty = () => {
      Promise.all(
        party.value.map(async (item) => {
          let foto = "";
          let name = "";
          let beforeAmount = 0;
          let beforePercent = 0;
          let afterAmount = 0;
          let afterPercent = 0;
          let resultPercent = 0;

          foto = item.party.logo;
          name = item.party.name;
          beforeAmount = await getCountParty(item.party.id, dataYear, dataId);
          beforePercent = total1.value ? (beforeAmount / total1.value) * 100 : 0;
          afterAmount = await getCountParty(
            item.party.id,
            parseInt(dataYear) + 5,
            dataId
          );
          afterPercent = total2.value ? (afterAmount / total2.value) * 100 : 1;

          if (afterAmount < beforeAmount) {
            resultPercent =
              ((beforeAmount - afterAmount) / (beforeAmount + afterAmount)) *
              100;
          } else {
            resultPercent =
              ((afterAmount - beforeAmount) / (beforeAmount + afterAmount)) *
              100;
          }
          data.value.push({
            id: item.party.id,
            img: path + foto,
            name: name,
            beforeAmount: beforeAmount,
            beforePercent: beforePercent,
            afterAmount: afterAmount,
            afterPercent: afterPercent,
            resultAmount: afterAmount - beforeAmount,
            resultPercent: resultPercent,
          });
        })
      ).then(() => {
        data.value.sort((a, b) => {
          return a.id - b.id;
        });
      });
    };

    watch(party1, () => {
      if (party1.value) {
        party1.value.map((item) => {
          total1.value += item.party.count;
        });
      }
    });

    watch(party2, () => {
      // console.log(party2.value);
      if (party2.value) {
        party2.value.map((item) => {
          total2.value += item.party.count;
        });
      }
    });

    watch([total1, total2], () => {
      const total = ref(0);
      const type = ref("party1");
      total.value =
        party1.value.length > party2.value.length
          ? party1.value.length
          : party2.value.length;
      type.value =
        party1.value.length > party2.value.length ? "party1" : "party2";

      if (total1.value || total2.value) {
        for (let i = 0; i < total.value; i++) {
          // loadData(i, type.value);
        }
      }

      if (total1.value != null && total2.value != null) {
        loadCountParty();
      }
    });

    const loadData = (i, type) => {
      let foto = "";
      let name = "";
      let beforeAmount = 0;
      let beforePercent = 0;
      let afterAmount = 0;
      let afterPercent = 0;
      let resultPercent = 0;
      const tipe = type == "party1" ? party1.value[i] : party2.value[i];
      const data1 = party1.value[i];
      const data2 = party2.value[i];

      if (data1 && tipe.party.id == data1.party.id) {
        foto = data1.party.logo;
        name = data1.party.name;
        beforeAmount = data1.party.count;
        beforePercent = (data1.party.count / total1.value) * 100;
      }

      if (data2 && tipe.party.id == data2.party.id) {
        foto = data2.party.logo;
        name = data2.party.name;
        afterAmount = data2.party.count;
        afterPercent = (data2.party.count / total2.value) * 100;
      }

      if (afterAmount < beforeAmount) {
        resultPercent =
          ((beforeAmount - afterAmount) / (beforeAmount + afterAmount)) * 100;
      } else {
        resultPercent =
          ((afterAmount - beforeAmount) / (beforeAmount + afterAmount)) * 100;
      }
      data.value.push({
        img: path + foto,
        name: name,
        beforeAmount: beforeAmount,
        beforePercent: beforePercent,
        afterAmount: afterAmount,
        afterPercent: afterPercent,
        resultAmount: afterAmount - beforeAmount,
        resultPercent: resultPercent,
      });
    };

    return {
      dataId,
      dataYear,
      data,
    };
  },
});
</script>
